$(document).ready(function () {
	initBooking();
	initTabLinks();
	initCustomSelect();
	initUpdatedBookingBar();

});



function initBooking() {
	$('.booking-bar__form, .booking-menu').each(function () {
		initDateSelection(this);
	});

	$('.booking-bar__why-book button, .booking-bar-popup__inner .close-button, .booking-menu__why-book button').on('click', function () {
		$('.booking-menu__close').trigger('click');
		$('.booking-bar-popup').slideToggle();
	});
	
	$('.booking-bar__why-book button').on('click', function (e) {
		e.preventDefault(); // Stop this button from also submitting the form
	});
	
	if ( $('.booking-bar__logo--right img').length > 0 ) {
		$('.booking-bar__wrapper').addClass('has-logo'); // Adds white border between booking bar and the logo, but only when the img element is there
	}
}

function initDateSelection(bookingContainer) {
	var nowTemp = new Date();

	// Don't allow bookings before the opening date
	var availability = $(bookingContainer).find('button[type="submit"]').data('availability');
	
	if (typeof availability === 'undefined') {
		var openingDate = new Date();
	} else {
		var openingDate = new Date(availability);
	}
	if ( nowTemp < openingDate ) {
		nowTemp = openingDate;
	}

	var tomorrow = new Date();
	tomorrow.setDate(nowTemp.getDate() + 1);

	var $checkInField = $(bookingContainer).find('.check-in');
	var $checkOutField = $(bookingContainer).find('.check-out');

	$checkInField.on('hide.datepicker', function () {
		var checkInDate  = $checkInField.datepicker('getDate');
		var checkOutDate = $checkOutField.datepicker('getDate');
		var dayAfterCheckIn = new Date(checkInDate);
		dayAfterCheckIn.setDate(checkInDate.getDate() + 1);

		// Ensure checkout comes after checkin.
		// Otherwise it all gets a bit Restaurant at the End of the Universe-ey.
		if ( checkInDate >= checkOutDate ) {
			$checkOutField.datepicker('setDate', dayAfterCheckIn);
		}

		$checkOutField.datepicker('setStartDate', dayAfterCheckIn);

		$checkOutField[0].focus();
	});

	var checkin = $checkInField.datepicker({
		weekStart: 1,
		format: 'dd/mm/yy',
		//autoPick: true,
		autoHide: true,
		date: null,
		startDate: nowTemp,
	});

	var checkout = $checkOutField.datepicker({
		weekStart: 1,
		format: 'dd/mm/yy',
		//autoPick: true,
		autoHide: true,
		date: null,
		startDate: tomorrow,

	});
	

	// Swap the date format into year-month-day for Synxis
	$(bookingContainer).on('submit', function () {
		var checkInDate  = $checkInField.datepicker('getDate');
		var checkOutDate = $checkOutField.datepicker('getDate');

		$(bookingContainer).find('[name="arrive"]').val(  checkInDate.getFullYear() + '-' + pad((checkInDate.getMonth()*1 + 1*1), 2) + '-' + pad((checkInDate.getDate()), 2)   );
		$(bookingContainer).find('[name="depart"]').val( checkOutDate.getFullYear() + '-' + pad((checkOutDate.getMonth()*1 + 1*1), 2) + '-' + pad((checkOutDate.getDate()), 2) );
	});





	var today = new Date().toLocaleDateString('en-GB')
	var tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toLocaleDateString('en-GB');
	var checkIn = $('.booking-menu__wrapper .check-in');
	var checkOut = $('.booking-menu__wrapper .check-out');
	checkIn.attr("placeholder", checkIn.data("ilhTemplate").replace("#", today));
	checkOut.attr("placeholder", checkOut.data("ilhTemplate").replace("#", tomorrow));		
}



function initTabLinks() {
	$('.tabs-title a').on('click', function () {
		if ( $(this).hasClass('link-not-tab') ) {
			console.log( $('#' + $(this).attr('href').replace('#', '')).find('a') );
			$('#' + $(this).attr('href').replace('#', '')).find('a')[0].click(); // click the link inside the tab when the tab title is clicked
			return false;
		}
	});
	
	
	$('select[name="booking-select"]').on('change', function () {
		if ( $(this).val() === 'gift' ) {
			$('.booking-menu__tabs a[href="#gift"]')[0].click();
		} else if ( $(this).val() === 'class' ) {
			$('.booking-menu__tabs a[href="#class"]')[0].click();
		} else if ( $(this).val().indexOf('table') > -1 ) {
			$('#table a[data-link="' + $(this).val() + '"]')[0].click();
		} else if ( $(this).val().indexOf('spa') > -1 ) {
			$('.book-now').trigger('click');
			$('#spa-label').trigger('click');
		}
	});
}


function initCustomSelect() {
	// Enumerate the table booking options
	var count = 0;
	$('.booking-bar__selector option').each(function () {
		if ($(this).attr('value') == 'table-booking') {
			$(this).attr('value', $(this).attr('value') + '-' + count);
			count++;
		}
	});
	
	count = 0;
	$('.booking-menu__links a').each(function () {
		if ( $(this).attr('data-link') == 'table-booking') {
			// console.log(this);
			$(this).attr('data-link', $(this).attr('data-link') + '-' + count);
			count++;
		}
	});
	
	$('.custom-select select').selectric();
}


function initUpdatedBookingBar() {
	var roomAccordion = $('.accordion.rooms')
	var guestAccordion = $('.accordion.guests')
	var roomSelectorBtn = $('.booking-rooms-summary')
	var guestSelectorBtn = $('.booking-bar-summary')

	var state = {
		isRoomActive: false,
		isGuestsActive: false
	}

	var values = {
		rooms: 0,
		adults: 2,
		children: 0,
		maxRoom:5,
		maxAdults:8,
		maxChildren:8,
	}

	var inputs = {
		rooms: $('.booking-bar-rooms'),
		adults: $('.booking-bar-adults'),
		children:$('.booking-bar-children')
	}

	var labels = {
		rooms: $('.rooms-value'),
		adults: $('.adults-value'),
		children: $('.children-value'),

	}

	function setDefaultValue(number, input) {
		input.val(number)
	}
	
	function setAccordion (state, element) {
		if (state) {
			element.show()
		} else {
			element.hide()
		}
	}

	function handleAccordionChange (state, element1, element2) {
		if (state) {
			element1.show()
			element2.hide()
		} else {
			element1.hide()
		}
	}

	function handleClickOutSideOfTarget (e, accordion, selector, state) {

		if (!accordion.is(e.target) 
			&& accordion.has(e.target).length === 0 
			&& !selector.is(e.target)) {

				state = false
				accordion.hide()
			} 
		
		return	
	}

	function toggleGuestsAccordion (e) {
		state.isGuestsActive = !state.isGuestsActive
		state.isRoomActive = false
		handleAccordionChange(state.isGuestsActive, guestAccordion, roomAccordion)
		if (e.target.id === 'booking-menu-summary') {
			$('#acc-bar-guests').css('opacity', 0)
		}
	}
	
	function toggleRoomAccordion (e) {
		state.isRoomActive = !state.isRoomActive
		state.isGuestsActive = false
		handleAccordionChange(state.isRoomActive, roomAccordion, guestAccordion)

		if (e.target.id === 'booking-menu-rooms') {
			$('#acc-bar-rooms').css('opacity', 0)
		}
		
	}

	function appendNumOfGuests () {
		var template = guestSelectorBtn.filter("[data-ilh-template]").data("ilhTemplate").replace("#", (values.adults + values.children));
		guestSelectorBtn.val(template)

	}

	function appendNumOfRooms () {
		var template = roomSelectorBtn.filter("[data-ilh-template]").data("ilhTemplate").replace("#", values.rooms);
		roomSelectorBtn.val(template)
	}

	function setData (value, targetInput, targetLabel) {
		targetInput.val(value)
		targetLabel.html(value)
	}

	function incrementRooms() {
		if (values.rooms < values.maxRoom) {
			values.rooms++
		}
		setData(values.rooms,inputs.rooms, labels.rooms)
		appendNumOfRooms()
	}

	function decrementRooms() {
		if (values.rooms > 0) {
			values.rooms--
		}
		setData(values.rooms,inputs.rooms, labels.rooms)
		appendNumOfRooms()
	}

	function incrementAdults() {
		if (values.adults < values.maxAdults) {
			values.adults++
		}
		setData(values.adults,inputs.adults, labels.adults)
		appendNumOfGuests()
	}

	function decrementAdults() {
		if (values.adults > 0) {
			values.adults--
		}
		setData(values.adults,inputs.adults, labels.adults)
		appendNumOfGuests()
	}

	function incrementChildren() {
		if (values.children < values.maxChildren) {
			values.children++
		}
		setData(values.children,inputs.children, labels.children)
		appendNumOfGuests()
	}

	function decrementChildren() {
		if (values.children > 0) {
			values.children--
		}
		setData(values.children,inputs.children, labels.children)
		appendNumOfGuests()
	}

	setDefaultValue(2, inputs.adults)
	setAccordion(state.isRoomActive, roomAccordion)
	setAccordion(state.isGuestsActive, guestAccordion)

	guestSelectorBtn.on('click', function (e) {
		toggleGuestsAccordion(e)
		appendNumOfGuests()
	})
	roomSelectorBtn.on('click', function (e) {
		toggleRoomAccordion(e)
	})
	$('.booking-bar').on('click', function (e) {
		handleClickOutSideOfTarget(e, roomAccordion, roomSelectorBtn, state.isRoomActive)
		handleClickOutSideOfTarget(e, guestAccordion, guestSelectorBtn, state.isGuestsActive)

	})
	$('.booking-menu').on('click', function (e) {
		handleClickOutSideOfTarget(e, roomAccordion, roomSelectorBtn, state.isRoomActive)
		handleClickOutSideOfTarget(e, guestAccordion, guestSelectorBtn, state.isGuestsActive)
	})
	$('.increment-rooms').on('click', incrementRooms)
	$('.decrement-rooms').on('click', decrementRooms)
	$('.increment-adults').on('click', incrementAdults)
	$('.decrement-adults').on('click', decrementAdults)
	$('.increment-children').on('click', incrementChildren)
	$('.decrement-children').on('click', decrementChildren)

	$('.booking-menu__close').on('click', function() {
		$('#acc-bar-rooms').css('opacity', 1)
		$('#acc-bar-guests').css('opacity', 1)
	})

}
